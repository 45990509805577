import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

function ContactForm() {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const statusMessages = {
    "Please provide either an email or phone number.": "error",
    "Please provide a message.": "error",
    "Please provide a valid email address.": "error",
    "Please provide a valid phone number with at least 8 digits.": "error",
    "There was an error sending your message. Please try again later.": "error",
    "Invalid request method.": "error",
    "✔ Thank you for your message. We will get back to you soon": "success"
  };

  const sanitizeInput = (input) => {
    return input.replace(/(<([^>]+)>)/gi, "").trim();
  };

  const validateForm = () => {
    if (!email && !phone) {
      setStatus('Please provide either an email or phone number.');
      setIsSuccess(false);
      return false;
    }
    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setStatus('Please provide a valid email address.');
      setIsSuccess(false);
      return false;
    }
    if (phone && !/^\d{8,}$/.test(phone)) {
      setStatus('Please provide a valid phone number with at least 8 digits.');
      setIsSuccess(false);
      return false;
    }
    if (!message) {
      setStatus('Please provide a message.');
      setIsSuccess(false);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const sanitizedEmail = sanitizeInput(email);
    const sanitizedPhone = sanitizeInput(phone);
    const sanitizedMessage = sanitizeInput(message);

    try {
      const response = await fetch('https://yandrtrading.com/api/emailhandler.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({ email: sanitizedEmail, phone: sanitizedPhone, message: sanitizedMessage }),
      });
      const data = await response.json();
      setStatus(data.message);
      setIsSuccess(data.success);
      if (data.success) {
        setEmail('');
        setPhone('');
        setMessage('');
      }
    } catch (error) {
      setStatus('An error occurred. Please try again.');
      setIsSuccess(false);
    }
  };

  const statusType = statusMessages[status];

  return (
    <div className="w-full lg:max-w-6xl mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg">
      <form onSubmit={handleSubmit} className="space-y-6" style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
        <div>
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">{t('email')}</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('enter_email')}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
          />
        </div>
        <div>
          <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-700">{t('phone')}</label>
          <input
            id="phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder={t('enter_phone')}
            style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
          />
        </div>
        <div>
          <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-700">{t('message')}</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('enter_message')}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
            required
            rows="4"
          ></textarea>
        </div>
        <button type="submit" className="w-full py-3 bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition duration-150 ease-in-out">
          {t('send_message')}
        </button>
        {status && (
          <p className={`mt-4 text-md ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
            {status}
          </p>
        )}
      </form>
    </div>
  );
}

export default ContactForm;
