import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Button } from "flowbite-react";
import { Link } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <div>
     {i18n.language === 'ar' && <Link gradientMonochrome="teal" onClick={() => changeLanguage('en')}>English</Link>}
      {i18n.language === 'en' && <Link gradientMonochrome="teal" className='flex'  onClick={() => changeLanguage('ar')}>العربية</Link>}
    </div>
  );
};

export default LanguageSwitcher;
