import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, lang }) => {
    return (
        <Helmet>
            <html lang={lang} />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="theme-color" content="#000000" />
            <link rel="icon" href="%PUBLIC_URL%/YrLogo.ico" />
        </Helmet>
    );
};

export default MetaTags;