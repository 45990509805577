import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const WideCard = forwardRef(({ linkto, icon: Icon, doeffect = false, app, text, isVisible }, ref) => {
  const effect = doeffect ? (
    <span className="relative flex h-4 w-4 mx-2">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
      <span className="relative inline-flex rounded-full h-4 w-4 bg-green-600"></span>
    </span>
  ) : null;

  const { t } = useTranslation();
  return (
    <a
      target="_block"
      href={linkto}
      ref={ref}
      className={`flex-1 max-w-md p-6 bg-white hover:bg-orange-100 stroke-0 fill-blue-950 text-center flex items-center justify-center transition-all duration-1000 ease-in-out transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}
    >
      {effect}
      <Icon className="w-10 h-10 stroke-blue-950 visited:fill-blue-950 visited:stroke-blue-950 mx-4" />
      <div className="ml-4">
        <h4 className="text-gray-600 text-md font-semibold">{t(app)}</h4>
        <p className="font-normal text-gray-700 dark:text-gray-400 group-hover:text-teal-400 group-hover:duration-700 text-xl">
          {text}
        </p>
      </div>
    </a>
  );
});

export default WideCard;
