import React, { useEffect, useRef, useState } from "react";
import Heading from "../components/Heading";
import WideCard from "../components/WideCard";
import { ReactComponent as whatsapp } from "../assets/icons/WhatsApp.svg";
import { ReactComponent as phone } from "../assets/icons/phone.svg";
import { ReactComponent as email } from "../assets/icons/email.svg";
import ContactForm from "../components/ContactForm";
// t
import { useTranslation } from "react-i18next";

function Contact() {
  // t
  const { t, i18n } = useTranslation();
  const cardsRef = useRef([]);
  const [visibleCards, setVisibleCards] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleCards((prev) => [...prev, entry.target]);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    cardsRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <div>
      <div
        className="relative overflow-hidden bg-cover bg-no-repeat bg-fixed bg-center"
        style={{
          backgroundImage:
            "url('https://www.arabianbusiness.com/cloud/2023/05/31/Sultan-Haitham-City-1.jpg')",
          height: "500px",
        }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.50)] bg-fixed">
          <div className="flex h-full items-center justify-center">
            <div className="px-6 text-center text-white md:px-12">
              <h1 className="mt-2 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
                <span className="block">{t("contact_title")}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section
        className="py-16 lg:px-32"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <div className="container mx-auto px-4">
          <div className="mb-8" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <Heading text="feedback_form" />
            <div className="md:w-2/3 mx-auto">
              <ContactForm />
            </div>
          </div>

          {/* <!-- Location --> */}
          <div className="mb-8" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <Heading text="location" />
            <div className="md:w-2/3 h-[30rem] mx-auto flex items-center justify-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3655.0130016096605!2d58.22586587282597!3d23.639705414653985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8dfd49ec0a56d5%3A0xee1ce76378b761d3!2sY%26R%20Trading%20LLC!5e0!3m2!1sen!2sus!4v1720993151220!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>

          {/* <!-- Contact Us --> */}
          <div className="mb-8" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <Heading text="contact_us" />
            <div className="flex md:flex-row gap-3 flex-col justify-center fill-blue-950 ">
              <WideCard
                ref={(el) => (cardsRef.current[0] = el)}
                isVisible={visibleCards.includes(cardsRef.current[0])}
                linkto="https://wa.me/+96899379966"
                icon={whatsapp}
                doeffect={true}
                app="whatsapp"
                text="+968 99379966"
              />
              <WideCard
                ref={(el) => (cardsRef.current[1] = el)}
                isVisible={visibleCards.includes(cardsRef.current[1])}
                linkto="tel:+96899379966"
                icon={phone}
                doeffect={false}
                app="phone"
                text="+968 99379966"
              />
              <WideCard
                ref={(el) => (cardsRef.current[2] = el)}
                isVisible={visibleCards.includes(cardsRef.current[2])}
                linkto="mailto:info@yandrtrading.com"
                icon={email}
                doeffect={false}
                app="email"
                text="info@yandrtrading.com"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
