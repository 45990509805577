import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import headLogo from "../assets/images/YrLogo.png";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { useTranslation } from "react-i18next";

function Header() {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const nav = document.querySelector("nav");
      const logo = document.getElementById("logo");

      if (window.scrollY > 0) {
        nav.classList.remove("bg-transparent", "text-white");
        nav.classList.add("bg-white", "text-black");
        logo.src = headLogo;
      } else {
        nav.classList.remove("bg-white", "text-black");
        nav.classList.add("bg-transparent", "text-white");
        logo.src = headLogo;
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const handleNavbarToggle = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const linkClass = ({ isActive }) => (isActive ? "text-yellow-400" : "");
  const location = useLocation();

  return (
    <div className="flex flex-wrap" style={{ direction: "rtl" }}>
      <section className="relative mx-auto">
        <nav className="fixed transition bg-transparent w-full z-20 top-0 start-0 border-b text-white">
          <div className="max-w-screen-2xl flex items-center justify-between py-4 mx-auto">
            <div className="pl-4 md:pl-8 lg:pl-12">
              <NavLink to="/">
                <img
                  src={headLogo}
                  id="logo"
                  className="h-12 md:h-12 lg:h-20"
                  alt="Logo"
                />
              </NavLink>
            </div>

            {/* Desktop Menu */}
            <div id="menu" className="hidden md:flex md:w-auto">
              <ul className="font-heading md:mx-10 mx-auto space-x-6 md:space-x-12 lg:space-x-16 font-semibold md:flex min-w-max">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `hover:text-yellow-200 py-2 md:py-4 lg:px-14 ${linkClass({
                        isActive,
                      })}`
                    }
                  >
                    {t("home")}
                  </NavLink>
                </li>
                <li className="group relative">
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      `cursor-pointer hover:text-yellow-200 py-2 md:py-6 lg:py-10 ${isActive || location.pathname === "/different"
                        ? "text-yellow-400"
                        : ""
                      }`
                    }
                  >
                    {t("about")}
                  </NavLink>
                  <ul className="absolute mt-4 hidden space-y-2 bg-gray-500 py-3 shadow-md group-hover:block min-w-max">
                    <li>
                      <NavLink
                        to="/about"
                        className={({ isActive }) =>
                          `block px-4 py-2 hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                          }`
                        }
                      >
                        {t("about_us")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/different"
                        className={({ isActive }) =>
                          `block px-4 py-2 hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                          }`
                        }
                      >
                        {t("different")}
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      `hover:text-yellow-200 py-2 md:py-4 lg:py-6 ${linkClass({
                        isActive,
                      })}`
                    }
                  >
                    {t("services")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      `hover:text-yellow-200 py-2 md:py-4 lg:py-6 ${linkClass({
                        isActive,
                      })}`
                    }
                  >
                    {t("contact")}
                  </NavLink>
                </li>
                <li>
                  <LanguageSwitcher />
                </li>
              </ul>
            </div>

            {/* Mobile Menu */}
            {isMobile && (
              <div className="md:hidden flex items-center">
                <button
                  onClick={handleNavbarToggle}
                  className="text-orange-200 hover:text-orange-100 focus:outline-none text-2xl"
                >
                  {isNavbarOpen ? "✖" : "☰"}
                </button>
                {isNavbarOpen && (
                  <div className="absolute top-0 right-0 mt-16 w-full bg-orange-100 shadow-lg z-50">
                    <ul className="font-heading font-bold flex flex-col items-center p-4 text-black hover:text-orange-100">
                      <li className="py-2">
                        <NavLink
                          to="/"
                          onClick={handleNavbarToggle}
                          className={({ isActive }) =>
                            `block hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                            }`
                          }
                        >
                          {t("home")}
                        </NavLink>
                      </li>
                      <li className="py-2">
                        <NavLink
                          to="/about"
                          onClick={handleNavbarToggle}
                          className={({ isActive }) =>
                            `block hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                            }`
                          }
                        >
                          {t("about_us")}
                        </NavLink>
                      </li>
                      <li className="py-2">
                        <NavLink
                          to="/different"
                          onClick={handleNavbarToggle}
                          className={({ isActive }) =>
                            `block hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                            }`
                          }
                        >
                          {t("different")}
                        </NavLink>
                      </li>
                      <li className="py-2">
                        <NavLink
                          to="/services"
                          onClick={handleNavbarToggle}
                          className={({ isActive }) =>
                            `block hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                            }`
                          }
                        >
                          {t("services")}
                        </NavLink>
                      </li>
                      <li className="py-2">
                        <NavLink
                          to="/contact"
                          onClick={handleNavbarToggle}
                          className={({ isActive }) =>
                            `block hover:text-yellow-200 ${isActive ? "text-yellow-400" : ""
                            }`
                          }
                        >
                          {t("contact")}
                        </NavLink>
                      </li>
                      <li className="py-2">
                        <LanguageSwitcher />
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </nav>
      </section>
    </div>
  );
}

export default Header;
