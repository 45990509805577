export const government_institutions = {
    modal_description: "government_institutions_description",
    accordion: [
        {
            accordion_title: "royal_oman_police",
            accordion_content: "royal_oman_police_content"
        },
        {
            accordion_title: "ministry_of_justice_and_legal_affairs",
            accordion_content: "ministry_of_justice_and_legal_affairs_content"
        }
    ]
};

export const companies = {
    modal_description: "companies_description",
    accordion:
    {
        accordion_title: "united_finance_company",
        accordion_content: "united_finance_company_content"
    },

}

export const investors = {
    modal_description: "investors_description",
}

export const banks = {
    modal_description: "banks_description",
    list: [
        'bn', 'ohb', 'aib', 'ab', 'ai', 'deb', 'di', 'db', 'oab'
    ]
}

export const individuals = {
    modal_description: "individuals_description",

}

export const audit_companies = {
    modal_description: "audit_companies_description",
}

export const property_valuation = {
    modal_description: "property_valuation_description",
    accordion: [
        {
            accordion_title: "real_estate",
            accordion_content: "real_estate_content"
        },
        {
            accordion_title: "vehicles",
            accordion_content: "vehicles_content"
        },
        {
            accordion_title: "equipment",
            accordion_content: "equipment_content"
        },
        {
            accordion_title: "commercial_register",
            accordion_content: "commercial_register_content"
        }
    ]
}

export const property_management = {
    modal_description: "property_management_description",
    accordion:
    {
        accordion_title: "real_estate_management",
        accordion_content: "real_estate_management_content"
    },

}

export const vehicle_rental = {
    modal_description: "vehicle_rental_description",
}

export const real_estate_brokerage = {
    modal_description: "real_estate_brokerage_description",
    accordion: [
        {
            accordion_title: "sale_purchase_mortgage_transactions",
            accordion_content: "sale_purchase_mortgage_transactions_content"
        },
        {
            accordion_title: "property_sale_rent_ads",
            accordion_content: "property_sale_rent_ads_content"
        }
    ]
}

export const general_cleaning = {
    modal_description: "general_cleaning_description",
}

export const human_resources_management = {
    modal_description: "human_resources_management_description",

}

export const buying_and_selling = {
    modal_description_part1: "buying_and_selling.modal_description_part1",
    modal_description_part2: "buying_and_selling.modal_description_part2",
}
