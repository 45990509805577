import React, { useEffect, useRef, useState } from 'react';

// t
import { useTranslation } from 'react-i18next';

const PartnerCard = ({ href, imgSrc, altText }) => {
    // t
    const { t } = useTranslation();

    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            ref={cardRef}
            className={`block p-6 rounded-lg border bg-white hover:bg-orange-100 transition-all duration-700 ease-in-out transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
                }`}
        >
            <img src={imgSrc} alt={altText} className="w-full h-32 object-contain mb-4" />
            <p className="text-center text-gray-700 font-semibold">{t(altText)}</p>
        </a>
    );
};

export default PartnerCard;
