import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
/**
 * A React component that renders a card with an icon and text, and opens a modal with more details when clicked.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.cardText="Sample"] - The text to display on the card.
 * @param {string} [props.modalTitle="Test"] - The title to display in the modal.
 * @param {React.Component} props.icon - The icon component to display on the card.
 * @param {React.ReactNode} props.children - The content to display inside the modal.
 *
 * @returns {JSX.Element} The rendered card and modal component.
 *
 * @example
 * // Example usage:
 * import { FaInfoCircle } from 'react-icons/fa';
 * 
 * <Modal cardText="Click for more info" modalTitle="Details" icon={FaInfoCircle}>
 *   <p>Here are more details...</p>
 * </Modal>
 */
function Modal({ cardText = "Sample", modalTitle = "Test", icon: Icon, children }) {
    const { t } = useTranslation();
    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => setOpenModal(false);

    const handleModalClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    useEffect(() => {
        const currentCardRef = cardRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (currentCardRef) {
            observer.observe(currentCardRef);
        }

        return () => {
            if (currentCardRef) {
                observer.unobserve(currentCardRef);
            }
        };
    }, []);

    return (
        <div>
            <div
                ref={cardRef}
                className={`flex flex-col items-center flex-grow justify-center p-4 rounded-lg bg-white hover:bg-orange-100 transition-all duration-1000 ease-in-out h-full w-full transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
                    }`}
            >
                <Icon className="w-12 h-12 text-blue-950 mb-2 group-hover:text-teal-400 transition-all duration-500" />
                <p className="text-lg font-semibold text-gray-900 text-center">{t(cardText)}</p>
                <p className="mt-2 text-blue-600 hover:text-blue-700 hover:underline cursor-pointer" onClick={() => setOpenModal(true)}>
                    {t('more_details')}
                </p>
            </div>

            {openModal && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto"
                    onClick={handleModalClick}
                >
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full  mx-4 md:mx-auto relative">
                        <button className="absolute top-3 right-3 text-gray-700 hover:text-gray-900" onClick={closeModal}>
                            &times;
                        </button>
                        <h3 className="text-xl font-semibold">{t(modalTitle)}</h3>
                        <div className="space-y-4 text-md font-semibold mt-4">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modal;
