import React, { useState } from 'react';
import { ReactComponent as Arrow } from '../assets/icons/KeyboardArrow.svg';
import { useTranslation } from 'react-i18next';

/**
 * A React component that renders an accordion with a title and content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title to display on the accordion header.
 * @param {string} props.content - The content to display inside the accordion body.
 *
 * @returns {JSX.Element} The rendered accordion component.
 *
 * @example
 * // Example usage:
 * <Accordion title="Accordion Title" content="This is the accordion content." />
 */
function Accordion({title, content}) {
    const { t, i18n } = useTranslation();
    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <div className='py-2  bg-transparent border-b border-yellow-400 '>
            <button className='flex justify-between w-full ' onClick={() => setAccordionOpen(!accordionOpen)}>
                <span className={`${i18n.language === 'ar' ? 'text-right':'text-left'} text-lg text-gray-800`}>{t(title)}</span>
                {accordionOpen ? <span className='duration-300 fill-yellow-400 '><Arrow /></span> : <span className='duration-300 rotate-180 fill-yellow-400'><Arrow/></span>}
            </button>
            <div className={`grid overflow-hidden w-auto  transition-all duration-300 ease-in-out text-gray-700  ${accordionOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}>
                <div className={`flex ${i18n.language === 'ar' ? 'text-right':'text-left'} text-lg font-normal py-1 px-2    overflow-hidden`}>{t(content)}</div>
            </div>
        </div>
    );
}

export default Accordion;
