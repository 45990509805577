import React, { useRef, useEffect } from 'react';

// t
import { useTranslation } from 'react-i18next';

function CardDifferent({ icon, text }) {

      // t
  const { t } = useTranslation();

    const cardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    }
                });
            },
            { threshold: 0.1 }
        );
        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <div className="w-full sm:w-1/2 md:w-1/4 p-4">
            <div
                ref={cardRef}
                className="card mx-auto p-10 rounded-lg text-center bg-white hover:bg-orange-100 text-black items-center justify-center transition-all group"
            >
                <svg
                    className="mx-auto w-16 text-blue-950 mb-3 group-hover:text-teal-400 group-hover:duration-500"
                    fill="none"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    {icon}
                </svg>
                <div className="min-w-max flex items-center justify-center flex-col">
                    <p className="mb-3 text-xl text-md font-semibold text-gray-900 min-w-max whitespace-pre-line">
                        {t(text)}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CardDifferent;
