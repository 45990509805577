import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

// pages
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Services from './pages/Services'
import Different from './pages/Different'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound';

//componets
import Header from './components/Header'
import Footer from './components/Footer'

// TransitionWrapper
import TransitionWrapper from './components/TransitionWrapper';

// scroll top
import ScrollToTop from './components/ScrollToTop';

// MetaTags
import MetaTags from './components/MetaTags';

// t
import { useTranslation } from 'react-i18next';

function App() {
  //t
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const metaInfo = {
    title: lang === 'ar' ? 'واي آند آر للتجارة ش م م' : 'Y&R Trading LLC',
    description: lang === 'ar'
      ? 'شركة يحيى وريا للتجارة ذ.م.م، عمان، متخصصة في تقديم خدمات العقارات المتكاملة، شراء وبيع العقارات، إدارة الممتلكات والمولات، تأجير السكني والتجاري، وتقييم الممتلكات.'
      : 'Yahya & Raiya Trading LLC, ( Y&R Trading), Oman, Specialized in providing integrated real estate services, buying and selling properties, property & mall management, renting residential and commercial , and valuation of property.',
  };

  return (
    <Router>
      <MetaTags title={metaInfo.title} description={metaInfo.description} lang={lang} />
      <ScrollToTop />
      <div className="App">
        <Header />
        <div>
          <TransitionWrapper>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/different" element={<Different />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </TransitionWrapper>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;