import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <p className="text-2xl mb-4">{t('page_not_found')}</p>
        <a href="/" className="text-blue-500 hover:text-blue-700">{t('go_home')}</a>
      </div>
    </div>
  );
};

export default NotFound;