import React from 'react'
import { useTranslation } from 'react-i18next';
/**
 * A React component that renders a list with optional icons and localization support.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array<string>} [props.data=['test', 'test']] - The list of items to display.
 * @param {React.Component} props.icon - The icon component to render alongside each list item.
 *
 * @returns {JSX.Element} The rendered list component.
 *
 * @example
 * // Example usage:
 * import { FaCheck } from 'react-icons/fa';
 * 
 * <List data={['item1', 'item2']} icon={FaCheck} />
 */
function List({ data = ['test', 'test'], icon: Icon }) {
    const { t, i18n } = useTranslation();
    return (
        <div className={`${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
            <div className="space-y-4 text-md font-semibold mt-4">
                <ul className="list-disc text-sm md:text-xl pl-5">
                    
                    {data.map((item) => (
                        <li className="flex items-center">
                            <Icon />
                            {t(item)}

                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
    )
}

export default List