import React from "react";
import Logo from "../assets/images/YrLogo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer className="bg-white text-left ltr:text-right  bg-[url('https://www.oman2040.om/uploads/slider/20230601205314-2023-06-01slider205311.webp')] bg-cover">
      <div className="bg-[hsla(0,0%,0%,0.50)]">
        <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
            {/* Navigation and Contact Section */}
            <div className="flex-grow grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 lg:mr-8">
              <div>
                <p className="font-medium text-white border-b-2 pb-2">
                  {t("main_menu")}
                </p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <Link
                      to="/"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      {t("home")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      {t("about_us")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      {t("services")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      {t("contact")}
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-white border-b-2 pb-2">
                  {t("quick_links")}
                </p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a
                      href="https://www.housing.gov.om/"
                      className="text-gray-300 transition hover:opacity-75"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("mhup")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.ohb.co.om/Default.aspx"
                      className="text-gray-300 transition hover:opacity-75"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("ohb")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://odb.om/"
                      className="text-gray-300 transition hover:opacity-75"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("deb")}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-white border-b-2 pb-2">
                  {t("contact_us")}
                </p>
                <ul className="mt-6 space-y-4 text-sm text-gray-300">
                  <li>
                    <p className="text-left ltr:text-right">{t("address")}</p>
                  </li>
                  <li>
                    <p>{t("phone")}</p>
                    <a
                      href="https://wa.me/+96899379966"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      +96899379966
                    </a>
                  </li>
                  <li>
                    <p>{t("email")}</p>
                    <a
                      href="mailto:Yr.trading@outlook.com"
                      className="text-gray-300 transition hover:opacity-75 overflow-clip"
                    >
                      info@yandrtrading.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Logo and Social Links Section */}
            <div className="flex-shrink-0 lg:order-2 text-center mt-8 lg:mt-0">
            <img src={Logo} className="w-32 mx-auto block" alt="Y&R Logo" />
              {/* todo */}
              <p className="mt-4 max-w-xs text-gray-300">
                {t("aboutus_our_story")}
              </p>
              <ul className="mt-8 flex gap-6 justify-center lg:justify-center">
                <li>
                  <a
                    href="https://www.instagram.com/y_and_r_/?igsh=Nm42aGlwbHJrYTJm"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white transition hover:opacity-75"
                  >
                    <span className="sr-only">Instagram</span>
                    <svg
                      className="h-6 w-6 mx-auto"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://wa.me/+96899379966"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white transition hover:opacity-75"
                  >
                    <span className="sr-only">Whatsapp</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 mx-auto"
                      fill="currentColor"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                  </a>
                </li>
                <li className="">
                  <a
                    href="https://maps.app.goo.gl/4LvjfJof2XRfy9EY9"
                    rel="noreferrer"
                    target="_blank"
                    className="transition hover:opacity-75 text-white" // Apply text-white here
                  >
                    <span className="sr-only">Location in Google Map</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      className="w-6 h-6 mx-auto fill-current" // Use fill-current here
                    >
                      <path d="M288 0c-69.6 0-126 56.4-126 126 0 56.3 82.4 158.8 113.9 196 6.4 7.5 17.8 7.5 24.2 0C331.7 284.8 414 182.3 414 126 414 56.4 357.6 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.1 216A32 32 0 0 0 0 245.7v250.3c0 11.3 11.4 19.1 21.9 14.9L160 448V214.9c-8.8-16-16.1-31.5-21.3-46.4L20.1 216zM288 359.7c-14.1 0-27.4-6.2-36.5-17-19.7-23.2-40.6-49.6-59.5-76.7v182l192 64V266c-18.9 27.1-39.8 53.5-59.5 76.7-9.1 10.8-22.4 17-36.5 17zm266.1-198.5L416 224v288l139.9-56A32 32 0 0 0 576 426.3V176c0-11.3-11.4-19.1-21.9-14.9z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <p className="text-xs text-gray-300 text-center lg:text-center">
            &copy; {currentYear} Y&R Trading L.L.C All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
