import React from 'react';
import Heading from '../components/Heading';
import SmallCard from '../components/SmallCard';
import Accordion from '../components/Accordion';
import Modal from '../components/Modal';
import List from '../components/List';
import * as Icons from '../assets/icons';
import * as data from './ServicesData';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t, i18n } = useTranslation();



    return (
        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
            <div className="wrapper">
                <div
                    className="relative overflow-hidden bg-cover bg-no-repeat bg-fixed bg-center"
                    style={{
                        backgroundImage: "url('https://www.arabianbusiness.com/cloud/2023/05/31/Sultan-Haitham-City-1.jpg')",
                        height: "500px",
                    }}
                >
                    <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.50)] bg-fixed">
                        <div className="flex h-full items-center justify-center">
                            <div className="px-6 text-center text-white md:px-12">
                                <h1 className="mt-2 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
                                    <span className="block">{t('our_services')}</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-16 lg:px-32">
                <div className="container mx-auto px-4 mb-16">
                    <Heading text="our_customers" />
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                        <Modal
                            cardText="government_institutions"
                            modalTitle="government_institutions"
                            icon={Icons.Government}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.government_institutions.modal_description)}
                            </p>
                            {data.government_institutions.accordion.map((item) => (
                                <Accordion title={item.accordion_title} content={item.accordion_content} />
                            ))}
                        </Modal>
                        <Modal
                            cardText="companies"
                            modalTitle="companies"
                            icon={Icons.Companies}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.companies.modal_description)}
                            </p>

                            <Accordion title={data.companies.accordion.accordion_title} content={data.companies.accordion.accordion_content} />

                        </Modal>
                        <Modal
                            cardText="investors"
                            modalTitle="investors"
                            icon={Icons.Investor}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.investors.modal_description)}
                            </p>

                        </Modal>
                        <Modal
                            cardText="banks"
                            modalTitle="banks"
                            icon={Icons.Bank}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.banks.modal_description)}
                            </p>

                            <List data={data.banks.list} icon={Icons.CheckMark} />

                        </Modal>
                        <Modal
                            cardText="individuals"
                            modalTitle="individuals"
                            icon={Icons.Individual}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.individuals.modal_description)}
                            </p>



                        </Modal>
                        <Modal
                            cardText="financial_audit_firms"
                            modalTitle="financial_audit_firms"
                            icon={Icons.Financial}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.audit_companies.modal_description)}
                            </p>

                        </Modal>
                    </div>
                </div>

                <div className="container mx-auto px-4 mb-16">
                    <Heading text="services" />
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">

                        <Modal
                            cardText="real_estate_valuation"
                            modalTitle="real_estate_valuation"
                            icon={Icons.Valuation}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.property_valuation.modal_description)}
                            </p>
                            {data.property_valuation.accordion.map((item) => (
                                <Accordion title={item.accordion_title} content={item.accordion_content} />
                            ))}
                        </Modal>

                        <Modal
                            cardText="real_estate_brokerage"
                            modalTitle="real_estate_brokerage"
                            icon={Icons.RealEstate}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.real_estate_brokerage.modal_description)}
                            </p>
                            {data.real_estate_brokerage.accordion.map((item) => (
                                <Accordion title={item.accordion_title} content={item.accordion_content} />
                            ))}
                        </Modal>


                        <Modal
                            cardText="property_management"
                            modalTitle="property_management"
                            icon={Icons.RealEstateManagement}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.property_management.modal_description)}
                            </p>
                            <Accordion title={data.property_management.accordion.accordion_title} content={data.property_management.accordion.accordion_content} />
                        </Modal>

                        <Modal
                            cardText="cleaning"
                            modalTitle="cleaning"
                            icon={Icons.GeneralCleaning}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.general_cleaning.modal_description)}
                            </p>

                        </Modal>
                        <Modal
                            cardText="vehicle"
                            modalTitle="vehicle"
                            icon={Icons.Vehicle}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.vehicle_rental.modal_description)}
                            </p>
                        </Modal>
                        <Modal
                            cardText="human_resources_management"
                            modalTitle="human_resources_management"
                            icon={Icons.HR}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.human_resources_management.modal_description)}
                            </p>
                        </Modal>
                        <Modal
                            cardText="Buying_and_selling"
                            modalTitle="Buying_and_selling"
                            icon={Icons.BuyingAndSelling}
                        >
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.buying_and_selling.modal_description_part1)}
                            </p>
                            <p className={i18n.language === 'ar' ? 'text-right' : 'text-left'}>
                                {t(data.buying_and_selling.modal_description_part2)}
                            </p>
                        </Modal>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Services;
