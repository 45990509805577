import React, { useEffect, useRef } from 'react';
import CeoPicture from '../assets/images/ceo_picture.jpg';
import CeoSignture from '../assets/images/ceo_signture.png';

// t
import { useTranslation } from 'react-i18next';
import Heading from '../components/Heading';
import CardAboutUs from '../components/CardAboutUs';

const About = () => {
  // t
  const { t, i18n } = useTranslation();
  const textRefs = useRef([]);
  const imageRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-in-right');
          observer.unobserve(entry.target);
        }
      });
    };

    const textObserver = new IntersectionObserver(handleIntersection, options);

    textRefs.current.forEach((ref) => {
      if (ref) {
        textObserver.observe(ref);
      }
    });

    const handleImageIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
          observer.unobserve(entry.target);
        }
      });
    };

    const imageObserver = new IntersectionObserver(handleImageIntersection, options);

    if (imageRef.current) {
      imageObserver.observe(imageRef.current);
    }

    return () => {
      textObserver.disconnect();
      imageObserver.disconnect();
    };
  }, []);

  return (
    <div style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}>
      {/* <!-- WRAPPER --> */}
      <div className="wrapper">
        {/* <!-- HERO SECTION --> */}
        <div className="relative overflow-hidden bg-cover bg-no-repeat bg-fixed" style={{
          backgroundPosition: "50%", backgroundImage: "url('https://constructive-voices.com/wp-content/uploads/2024/04/Oman-Sacred-Natural-Sites-and-Biodiversity.jpg')", height: "600px"
        }}>
          <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.50)] bg-fixed">
            <div className="flex h-full items-center justify-center">
              <div className="px-6 text-center text-white md:px-12">
                <h1 className="mt-2 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
                  {t('aboutus_title')}
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- HERO TEXT --> */}
      </div>
      {/* <!-- END HERO TEXT --> */}
      <div className="container mx-auto lg:px-32">

        <CardAboutUs />

        {/* <!-- header section --> */}
        <div className="container mx-auto px-4 ">

          {/* our story */}
          <div className="mb-14" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
            <Heading text="our_story" />
            <p ref={(el) => textRefs.current[0] = el} className={`text-lg ${i18n.language === 'en' ? 'text-left' : 'text-right'}`}>
              {t('aboutus_our_story')}
            </p>
          </div>

          {/* our vision */}
          <div className="mb-14" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
            <Heading text="our_vision" />
            <p ref={(el) => textRefs.current[1] = el} className={`text-lg ${i18n.language === 'en' ? 'text-left' : 'text-right'}`}>
              {t('aboutus_our_vision')}
            </p>
          </div>

          {/* collaboration */}
          <div className="mb-14" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
            <Heading text="collaboration" />
            <p ref={(el) => textRefs.current[2] = el} className={`text-lg ${i18n.language === 'en' ? 'text-left' : 'text-right'}`}>
              {t('aboutus_collaboration')}
            </p>
          </div>

          <div className="mb-14" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
            {/* CEO MESSAGE SECTION  */}
            <Heading text='ceo_message' />
            <div className='grid grid-cols-2 rtl:text-right ltr:text-left gap-4'>
              {/* CEO Picture */}
              <figure className="max-w-lg mt-4" ref={imageRef}>
                <img className="h-auto max-w-full rounded-lg" src={CeoPicture} alt="MR. YAHYA KHAMIS AL AMRI - CEO" />
                <figcaption className="mt-2 text-sm text-center text-gray-500">{t('ceo_name')}</figcaption>
              </figure>

              {/* CEO Message */}
              <div className='flex flex-col'>
                <blockquote className="p-4 my-4 border-s-4 border-yellow-300 bg-yellow-50 text-sm lg:text-lg text-pretty text-yellow-800 place-self-start">
                  <svg className="w-8 h-8 text-yellow-400 mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                  </svg>
                  <p className='text-balance'>{t('ceo_words')}</p>
                </blockquote>
                <img className='mix-blend-multiply sepia w-1/3 place-self-end' src={CeoSignture} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--END WRAPPER--> */}
    </div>
  );
};

export default About;
