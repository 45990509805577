import React, { useEffect, useRef } from 'react'

// t
import { useTranslation } from 'react-i18next';

function CardAboutUs() {
    // t
    const { t, i18n } = useTranslation();
    const countersRef = useRef([]);

    useEffect(() => {
        const options = {
            threshold: 0.5,
        };

        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const target = entry.target;
                    const countTo = parseInt(target.getAttribute('data-count'), 10);
                    let count = 0;
                    const duration = 2000;
                    const increment = countTo / (duration / 16);

                    const updateCount = () => {
                        count += increment;
                        if (count < countTo) {
                            target.innerText = Math.ceil(count);
                            requestAnimationFrame(updateCount);
                        } else {
                            target.innerText = countTo;
                        }
                    };

                    requestAnimationFrame(updateCount);
                    observer.unobserve(target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        countersRef.current.forEach((counter) => {
            observer.observe(counter);
        });

        return () => {
            observer.disconnect();
        };
    }, []);
    return (
        <div className="container flex md:flex-row flex-col space-x-3 lg:px-5" style={{ direction: "ltr" }}>
            {/* <!--Card 1--> */}
            <div className="gap-3 flex-1 p-4">
                <div className="mx-auto p-10 rounded-lg text-center bg-white hover:bg-orange-100 text-black items-center justify-center transition-all group hover:duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mx-auto w-16 text-blue-950 mb-3 group-hover:text-teal-400 group-hover:duration-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                    </svg>
                    <div className="min-w-max flex items-center justify-center flex-col">
                        <h5 className="mb-2 text-md text-4xl font-semibold tracking-tight min-w-max text-gray-900 group-hover:text-teal-400 group-hover:duration-500">
                            <span ref={(el) => countersRef.current[0] = el} data-count="30">0</span>+
                        </h5>
                        <p className="mb-3 text-xl text-gray-900 min-w-max text-md font-semibold">{t('aboutus_card_1')}</p>
                    </div>
                </div>
            </div>
            {/* <!--Card 2--> */}
            <div className="gap-3 flex-1 p-4">
                <div className="mx-auto p-10 rounded-lg text-center bg-white hover:bg-orange-100 text-black items-center justify-center transition-all group hover:duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mx-auto w-16 text-blue-950 mb-3 group-hover:text-teal-400 group-hover:duration-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                    </svg>
                    <div className="min-w-max flex items-center justify-center flex-col">
                        <h5 className="mb-2 text-md text-4xl font-semibold tracking-tight min-w-max text-gray-900 group-hover:text-teal-400 group-hover:duration-500">
                            <span ref={(el) => countersRef.current[1] = el} data-count="16">0</span>+
                        </h5>
                        <p className="mb-3 text-xl text-gray-900 min-w-max text-md font-semibold">{t('aboutus_card_2')}</p>
                    </div>
                </div>
            </div>
            {/* <!--Card 3--> */}
            <div className="gap-3 flex-1 p-4">
                <div className="mx-auto p-10 rounded-lg text-center bg-white hover:bg-orange-100 text-black items-center justify-center transition-all group hover:duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mx-auto w-16 text-blue-950 mb-3 group-hover:text-teal-400 group-hover:duration-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                    </svg>
                    <div className="min-w-max flex items-center justify-center flex-col">
                        <h5 className="mb-2 text-md text-4xl font-semibold tracking-tight min-w-max text-gray-900 group-hover:text-teal-400 group-hover:duration-500">
                            <span ref={(el) => countersRef.current[2] = el} data-count="2017">2017</span>
                        </h5>
                        <p className="mb-3 text-xl text-gray-900 min-w-max text-md font-semibold">{t('aboutus_card_3')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardAboutUs