import React from 'react';
import PartnerCard from '../components/PartnerCard';
import logo from '../assets/images/YrLogo.png';

// logos
import RoyalOmanPolice from '../assets/images/ROP.jpg';
import SupremeJudiciaryCouncil from '../assets/images/sjc.jpg';
import BankNizwa from '../assets/images/bn.jpg';
import AlizzIslamicBank from '../assets/images/ai.jpg';
import AhliBank from '../assets/images/ab.jpg';
import AhliIslamic from '../assets/images/aib.jpg';
import OmanDevelopmentBank from '../assets/images/db.jpg';
import DhofarIslamic from '../assets/images/di.jpg';
import DhofarBank from '../assets/images/bd.jpg';
import OmanArabicBank from '../assets/images/oab.jpg';
import OmanHousingBank from '../assets/images/ohb.jpg';
import MinistryOfHousingAndUrbanPlanning from '../assets/images/mhup.jpg';
import Ufcoman from '../assets/images/ufc.jpg'

import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  const partners = [
    { href: 'https://www.ohb.co.om/Default.aspx', imgSrc: OmanHousingBank, altText: 'ohb' },
    { href: 'https://www.housing.gov.om/', imgSrc: MinistryOfHousingAndUrbanPlanning, altText: 'mhup' },
    { href: 'https://www.sjc.gov.om/?culture=en', imgSrc: SupremeJudiciaryCouncil, altText: 'sjc' },
    { href: 'https://www.rop.gov.om/english/', imgSrc: RoyalOmanPolice, altText: 'rop' },
    { href: 'https://www.banknizwa.om/', imgSrc: BankNizwa, altText: 'bn' },
    { href: 'https://ahliislamic.om/?lang=ar', imgSrc: AlizzIslamicBank, altText: 'ai' },
    { href: 'https://ahlibank.om/?lang=ar', imgSrc: AhliBank, altText: 'ab' },
    { href: 'https://alizzislamic.com/', imgSrc: AhliIslamic, altText: 'aib' },
    { href: 'https://odb.om/', imgSrc: OmanDevelopmentBank, altText: 'deb' },
    { href: 'https://www.dhofarislamicbank.com/ar/', imgSrc: DhofarIslamic, altText: 'di' },
    { href: 'https://www.bankdhofar.com/ar/', imgSrc: DhofarBank, altText: 'db' },
    { href: 'https://www.oman-arabbank.com/ar/', imgSrc: OmanArabicBank, altText: 'oab' },
    // { href: 'https://www.ufcoman.com/home', imgSrc: Ufcoman, altText: 'ufc' },
  ];

  return (
    <div>
      <div
        className={`relative bg-cover bg-no-repeat bg-center bg-fixed bg-fixed-ios`}
        style={{
          backgroundImage: "url('https://www.oman2040.om/uploads/slider/20230601205314-2023-06-01slider205311.webp')",
          height: '100vh',
        }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.40)]">
          <div className="flex h-full items-center justify-center">
            <div className="px-6 text-center text-white md:px-12">
              <div className="flex flex-col items-center">
                <img width="300px" src={logo} alt="logo" />
                <p className="text-2xl font-semibold mt-4 text-yellow-300 ">{t('company_title')}</p>
                <br />
                <p className="text-2xl font-semibold drop-shadow-lg">
                  <span className=''>{t('home_hero_text1')}<br />{t('home_hero_text2')}<br /></span><span>{t('home_hero_text3')}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="container mx-auto mt-10 mb-14 px-4 sm:px-6 lg:px-8">
        <h5 className="text-neutral-800 text-3xl font-semibold text-center uppercase">{t('partners')}</h5>
        <hr className="w-24 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-6">
          {partners.map((partner, index) => (
            <PartnerCard key={index} href={partner.href} imgSrc={partner.imgSrc} altText={partner.altText} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
