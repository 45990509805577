import React from "react";
import { useTranslation } from 'react-i18next';


function Heading({ text = "Heading" }) {
  const { t } = useTranslation();

  return (

    <h1
      className="rtl:bottom-line-ar ltr:bottom-line font-bold text-left rtl:text-right text-2xl md:text-3xl uppercase ltr:tracking-wider"
    >
      {t(text)}
    </h1>
  );
}

export default Heading;
